





























import {Component, Vue} from 'vue-property-decorator';
import MenuItemView, {MenuItem} from './AppSidebarMenuItem.vue';

@Component({
  components: {
    MenuItemView,
  },
})
export default class AppSidebarMenu extends Vue {
  private get menuGroups(): MenuItem[][] {
    return [
      [
        {
          label: 'Распределение',
          icon: 'music-note',
          route: {
            name: 'tracksDistribution',
          },
        },

        {
          label: 'Подборки',
          icon: 'playlist-music-outline',
          route: {
            name: 'compilation',
          },
        },

        {
          label: 'Треки',
          icon: 'music-note2',
          route: {
            name: 'track',
          },
        },

        {
          label: 'Пресеты',
          icon: 'tune',
          route: {
            name: 'preset',
          },
        },

        {
          label: 'Плейлисты',
          icon: 'list-alt',
          route: {
            name: 'playlist',
          },
        },
        {
          label: 'Теги',
          icon: 'sell',
          route: {
            name: 'tag',
          },
        },
      ],
      [
        {
          label: 'Добавить альбом',
          icon: 'upload',
          route: {
            name: 'uploadAlbum',
          },
        },
      ],
    ];
  }
}
