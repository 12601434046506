















































import {Component, Vue} from 'vue-property-decorator';
import AppSidebar from '@/components/app-sidebar/AppSidebar.vue';
import MusicPlayer from '@/components/music-player/Player.vue';

@Component({
  components: {
    AppSidebar,
    MusicPlayer,
  },
})
export default class extends Vue {
}
